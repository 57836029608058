import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import type { Entry } from '../app/models';
import { selectImagePath } from '../imageview/imageViewSlice';
import EntryIcon from './EntryIcon';
import styles from './FileBrowser.module.css';

interface Props {
  entries: Entry[];
  numHiddenEntries: number;
  showFilterHint: boolean;
  onEntryClick: (file: Entry) => void;
  goBackToParentFolder: (() => void) | undefined;
}

function EntryListGroup(props: Props) {
  const {
    entries,
    numHiddenEntries,
    showFilterHint,
    onEntryClick,
    goBackToParentFolder,
  } = props;
  const currentImagePath = useSelector(selectImagePath);

  return (
    <ListGroup className={styles.list} role="list">
      {goBackToParentFolder && (
        <ListGroup.Item
          className={styles.item}
          action
          onClick={() => goBackToParentFolder()}
        >
          <span className={styles.entityIcon}>
            <EntryIcon type="dir" />
          </span>
          ..
        </ListGroup.Item>
      )}
      {entries.map((entry) => (
        <OverlayTrigger
          key={entry.path}
          placement="right"
          delay={{ show: 750, hide: 150 }}
          overlay={
            <Tooltip id="path-tooltip" className={styles.pathTooltip}>
              {entry.name}
            </Tooltip>
          }
        >
          <ListGroup.Item
            key={entry.path}
            className={styles.item}
            action
            onClick={() => onEntryClick(entry)}
            data-current={entry.path === currentImagePath || undefined}
          >
            <span className={styles.entityIcon}>
              <EntryIcon type={entry.type} />
            </span>
            {entry.name}
          </ListGroup.Item>
        </OverlayTrigger>
      ))}
      {showFilterHint && (
        <ListGroup.Item className={styles.hintItem}>
          <span className={styles.entityIcon}>
            <EntryIcon type="dir" />
          </span>
          {numHiddenEntries} hidden
        </ListGroup.Item>
      )}
    </ListGroup>
  );
}

export default EntryListGroup;
