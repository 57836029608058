import { ToggleGroup } from '@h5web/lib';
import { ArrowDown, Clock, SortAlphaDown } from 'react-bootstrap-icons';
import type { SortingMethod } from './models';
import styles from './FileBrowser.module.css';

interface Props {
  value: SortingMethod;
  onChange: (v: SortingMethod) => void;
}

function SortInput(props: Props) {
  const { value, onChange } = props;

  return (
    <div className={styles.sortInput}>
      <ToggleGroup
        role="radiogroup"
        value={value}
        onChange={(v) => onChange(v as SortingMethod)}
      >
        <ToggleGroup.Btn
          icon={SortAlphaDown}
          iconOnly
          label="Sort by name"
          value="name"
        />
        <ToggleGroup.Btn
          icon={() => (
            <>
              <ArrowDown />
              <Clock />
            </>
          )}
          iconOnly
          label="Sort by most recent"
          value="time"
        />
      </ToggleGroup>
    </div>
  );
}

export default SortInput;
