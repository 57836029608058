import { useUnmountEffect } from '@react-hookz/web';
import { ProgressBar } from 'react-bootstrap';
import { useProgress } from '../client/stores';
import styles from './ImageLoader.module.css';

function ImageLoader() {
  const progress = useProgress((state) => state.progress);

  const resetProgress = useProgress((state) => state.resetProgress);
  useUnmountEffect(() => resetProgress());

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>

      <ProgressBar now={progress} variant="info" />
    </div>
  );
}

export default ImageLoader;
