import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { BraggyFile } from '../app/models';
import type { RootState } from '../app/store';

interface ImageViewState {
  error: string | null | undefined;
  loading: boolean;
  currentFile: BraggyFile | null;
  path: string | undefined;
}

const initialState: ImageViewState = {
  error: null,
  loading: false,
  currentFile: null,
  path: undefined,
};

// ImmerJS used for state in createSlice so assignment can
// be done directly to state
const imageViewSlice = createSlice({
  name: 'imageview',
  initialState,
  reducers: {
    setImagePath: (state, action: PayloadAction<string>) => {
      state.path = action.payload;
    },
  },
});

export const selectImagePath = (state: RootState) => state.imageview.path;

export const { setImagePath } = imageViewSlice.actions;
export default imageViewSlice.reducer;
