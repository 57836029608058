import { range } from 'lodash';
import { Vector2 } from 'three';

export function pointsCrossedByLine(start: Vector2, end: Vector2): Vector2[] {
  const { x: x0, y: y0 } = start;
  const { x: x1, y: y1 } = end;

  const dx = x1 - x0;
  const dy = y1 - y0;

  const a = (y1 - y0) / (x1 - x0);
  // y = a*(x - x0) + y0
  if (Math.abs(dx) > Math.abs(dy)) {
    const xs = range(x0, x1, Math.sign(dx));
    return xs.map((x) => new Vector2(x, a * (x - x0) + y0));
  }

  const ys = range(y0, y1, Math.sign(dy));
  return ys.map((y) => new Vector2(x0 + (y - y0) / a, y));
}

export function pointsCrossedByCircle(
  center: Vector2,
  circlePoint: Vector2
): Vector2[] {
  const r = center.distanceTo(circlePoint);

  const x0 = center.x;
  const y0 = center.y;

  const ys = range(0, Math.ceil((Math.sqrt(2) * r) / 2));
  return ys.reduce<Vector2[]>(
    (acc, y) => {
      const dy = y + 1;
      const dx = Math.sqrt(r ** 2 - (y + 1) ** 2);
      acc.push(
        new Vector2(x0 + dx, y0 + dy),
        new Vector2(x0 - dx, y0 + dy),
        new Vector2(x0 - dx, y0 - dy),
        new Vector2(x0 + dx, y0 - dy),
        new Vector2(x0 + dy, y0 + dx),
        new Vector2(x0 - dy, y0 + dx),
        new Vector2(x0 - dy, y0 - dx),
        new Vector2(x0 + dy, y0 - dx)
      );

      return acc;
    },
    [
      new Vector2(x0, y0 + r),
      new Vector2(x0, y0 - r),
      new Vector2(x0 - r, y0),
      new Vector2(x0 + r, y0),
    ]
  );
}
