import { Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './routes';
import history from './history';
import LoginPage from '../login/LoginPage';
import MainPage from './MainPage';

export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/">
          <MainPage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
