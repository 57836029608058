import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-reflex/styles.css';
import './index.css';
import '@h5web/lib/dist/styles.css';

import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import App from './app/App';
import { reportWebVitals } from './app/utils';
import { createStore } from './app/store';
import { unregister } from './serviceWorker';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './app/ErrorFallback';

const store = createStore();

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <SWRConfig value={{ provider: () => new Map() }}>
        <Provider store={store}>
          <App />
        </Provider>
      </SWRConfig>
    </ErrorBoundary>
  </StrictMode>,
  document.querySelector('#root')
);

if (process.env.REACT_APP_WEB_VITALS_ENABLED === 'true') {
  reportWebVitals();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
