import { useEffect } from 'react';
import type { Entry } from '../app/models';
import type { SortingMethod } from './models';
import { useDirectory } from '../client/stores';
import EntryListGroup from './EntryListGroup';

interface Props {
  path: string;
  filter: string;
  sortBy: SortingMethod;
  onEntryClick: (file: Entry) => void;
  onDirectoryFetch: (numEntries: number, numMatchingEntries: number) => void;
  goBackToParentFolder: (() => void) | undefined;
}

function EntryListGroupContainer(props: Props) {
  const {
    path,
    filter,
    onEntryClick,
    onDirectoryFetch,
    sortBy,
    goBackToParentFolder,
  } = props;

  function compareEntries(a: Entry, b: Entry): number {
    if (a.type !== b.type) {
      // Put directories before files
      return a.type === 'dir' ? -1 : 1;
    }

    if (sortBy === 'time') {
      // Descending sorting
      return b.last_modified - a.last_modified;
    }

    return a.name < b.name ? -1 : 1;
  }

  const entries = useDirectory(path);

  const words = filter.trim().toLowerCase().split(/\s+/u);
  const matchingEntries = entries
    .filter((entry) => words.every((w) => entry.name.toLowerCase().includes(w)))
    .sort(compareEntries);

  const numEntries = entries.length;
  const numMatchingEntries = matchingEntries.length;

  useEffect(() => {
    onDirectoryFetch(numEntries, numMatchingEntries);
  }, [numEntries, numMatchingEntries, onDirectoryFetch]);

  return (
    <EntryListGroup
      entries={matchingEntries}
      numHiddenEntries={numEntries - numMatchingEntries}
      showFilterHint={numMatchingEntries === 0 && numEntries > 0}
      onEntryClick={onEntryClick}
      goBackToParentFolder={goBackToParentFolder}
    />
  );
}

export default EntryListGroupContainer;
