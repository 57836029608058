import { ListGroup } from 'react-bootstrap';
import { Folder } from 'react-bootstrap-icons';
import styles from './FileBrowser.module.css';

function DirectoryLoader() {
  return (
    <ListGroup className={styles.list}>
      <ListGroup.Item className={styles.item}>
        <span className={styles.entityIcon}>
          <Folder />
        </span>
        Loading...
      </ListGroup.Item>
    </ListGroup>
  );
}

export default DirectoryLoader;
