import { SelectionLine, SelectionTool } from '@h5web/lib';
import { useState } from 'react';
import type { Vector2 } from 'three';
import { CIRCLE_PROFILE_KEY, LINE_PROFILE_KEY } from '../utils';
import type { PersistedSelection } from './models';
import PersistedSelectionShape from './PersistedSelectionShape';
import SelectionCircle from './SelectionCircle';
import { pointsCrossedByCircle, pointsCrossedByLine } from './utils';

interface Props {
  color: string;
  onSelection: (points: Vector2[]) => void;
}

function ProfileSelectionTool(props: Props) {
  const { color, onSelection } = props;
  const [lastSelection, setLastSelection] = useState<PersistedSelection>();

  return (
    <>
      <SelectionTool
        onSelectionStart={() => setLastSelection(undefined)}
        onSelectionEnd={(selection) => {
          const pts = pointsCrossedByLine(
            selection.startPoint,
            selection.endPoint
          );
          onSelection(pts);
          setLastSelection({ selection, shape: 'line' });
        }}
        modifierKey={LINE_PROFILE_KEY}
        id="LineSelection"
      >
        {(selection) => <SelectionLine stroke={color} {...selection} />}
      </SelectionTool>
      <SelectionTool
        onSelectionStart={() => setLastSelection(undefined)}
        onSelectionEnd={(selection) => {
          const pts = pointsCrossedByCircle(
            selection.startPoint,
            selection.endPoint
          );
          onSelection(pts);
          setLastSelection({ selection, shape: 'circle' });
        }}
        modifierKey={CIRCLE_PROFILE_KEY}
        id="CircleSelection"
      >
        {(selection) => <SelectionCircle stroke={color} {...selection} />}
      </SelectionTool>
      {lastSelection && (
        <PersistedSelectionShape color={color} {...lastSelection} />
      )}
    </>
  );
}

export default ProfileSelectionTool;
