import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
import { fetchRootPath } from '../client/api';

const initialState = {
  path: '.',
  rootPath: '',
};

export const getRootPath = createAsyncThunk<string>(
  'filebrowser/directory',
  () => fetchRootPath()
);

const filebrowserSlice = createSlice({
  name: 'filebrowser',
  initialState,
  reducers: {
    setDirectoryPath: (state, action: PayloadAction<string>) => {
      state.path = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRootPath.fulfilled, (state, { payload }) => {
      state.rootPath = payload;
    });
    builder.addCase(getRootPath.rejected, (state, { payload }) => {
      state.rootPath = payload as string;
    });
  },
});

export const { setDirectoryPath } = filebrowserSlice.actions;

export const selectDirectoryPath = (state: RootState) => state.filebrowser.path;
export const selectRootPath = (state: RootState) => state.filebrowser.rootPath;

export default filebrowserSlice.reducer;
