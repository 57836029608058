import React, { useState } from 'react';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import { Form, Button, Container, Col, Row, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from './logo.png';

import { updateUser } from './loginSlice';
import type { RootState } from '../app/store';
import styles from './LoginPage.module.css';
import type { Location } from 'history';

interface LocationState {
  from: Location<unknown>;
}

export default function LoginPage() {
  const location = useLocation<LocationState | undefined>();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.users);

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      updateUser({
        username: state.username,
        password: state.password,
        from: location.state?.from || '/',
      })
    );
  };

  return (
    <div className={styles.root}>
      <Container className="pt-3 pb-5">
        <Row className="align-items-center">
          <Col lg={8} xl={7}>
            <Row className="mb-3">
              <Col xs="auto" style={{ marginLeft: '-6px' }}>
                <img
                  className={styles.logo}
                  style={{ maxWidth: '100%' }}
                  src={logo}
                  width="150"
                  alt="ESRF - European Synchrotron Research and Elettra Facilities"
                />
              </Col>
              <Col>
                <h1 className="display-3 mb-3" style={{ marginTop: '-2px' }}>
                  Braggy
                </h1>
                <p className="fs-3 text-muted" style={{ maxWidth: '15em' }}>
                  Diffraction image visualisation in&nbsp;the&nbsp;browser
                </p>
                <p>
                  <a
                    className="link-dark"
                    style={{ whiteSpace: 'nowrap' }}
                    href="https://www.esrf.fr/braggy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Features overview
                    <BoxArrowUpRight
                      className="ms-2"
                      size="0.75em"
                      aria-label="opens in new window"
                    />
                  </a>{' '}
                  <span className="mx-2">|</span>{' '}
                  <a
                    className="link-dark"
                    style={{ whiteSpace: 'nowrap' }}
                    href="https://gitlab.esrf.fr/ui/h5web-braggy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GitLab repository
                    <BoxArrowUpRight
                      className="ms-2"
                      size="0.75em"
                      aria-label="opens in new window"
                    />
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <h2 className="mb-4">Log in</h2>
            <Form noValidate onSubmit={handleSubmit}>
              {error && (
                <Alert variant="danger">
                  There was an error. Please retry later.
                </Alert>
              )}

              <p>Please log in with your full email and VUO password.</p>
              <Form.FloatingLabel
                controlId="username"
                className="mb-3"
                label="Full email as in VUO"
              >
                <Form.Control
                  type="text"
                  name="username"
                  required
                  placeholder="name@example.com"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please check if this email is registered in VUO
                </Form.Control.Feedback>
              </Form.FloatingLabel>

              <Form.FloatingLabel
                controlId="password"
                className="mb-3"
                label="Password"
              >
                <Form.Control
                  type="password"
                  name="password"
                  required
                  placeholder="*****"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a password
                </Form.Control.Feedback>
              </Form.FloatingLabel>

              <Button variant="dark" type="submit" disabled={loading}>
                {loading ? 'Logging in...' : 'Log in'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
