import { io, Socket } from 'socket.io-client';
import { each } from 'lodash';

class SocketIOClient {
  public baseUrl: string;
  public onConnectErrorCB: (() => void) | null;
  public onUnauthorisedCB: (() => void) | null;
  public sockets: Record<string, Socket>;
  public onUnauthorisedFired: boolean;

  public constructor() {
    this.baseUrl = process.env.REACT_APP_DAIQUIRI_URL || '';
    this.onUnauthorisedFired = false;
    this.onConnectErrorCB = null;
    this.onUnauthorisedCB = null;
    this.sockets = {};
  }

  public setup(options: {
    baseUrl: string;
    onUnauthorisedCB?: (() => void) | null;
    onConnectErrorCB?: (() => void) | null;
  }): void {
    this.baseUrl = options.baseUrl.replace('/api', '');
    this.onConnectErrorCB = options.onConnectErrorCB || null;
    this.onUnauthorisedCB = options.onUnauthorisedCB || null;
  }

  public openSockets(): void {
    const token = this.getToken();
    each(this.sockets, (socket: Socket) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      socket.io.opts.query!.token = token;
      if (!socket.connected) {
        socket.open();
      }
    });
  }

  public closeSockets(): void {
    each(this.sockets, (socket: Socket) => {
      if (socket.connected) {
        socket.close();
      }
    });
  }

  public getToken(): string {
    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No auth token !');
    }
    return token;
  }

  public addNamespace(namespace: string): void {
    if (namespace in this.sockets) {
      return;
    }

    const socket: Socket = io(`${this.baseUrl}/${namespace}`, {
      query: { token: '' },
      autoConnect: false,
      // reconnection: false,
      reconnectionDelayMax: 30_000,
    });

    socket.on('connect', this.onConnect.bind(this, namespace));
    socket.on('disconnect', this.onDisconnect.bind(this, namespace));
    socket.on('connect_error', this.onConnectError.bind(this, namespace));

    this.sockets[namespace] = socket;
  }

  public addCallback(
    namespace: string,
    event: string,
    callback: (payload: unknown) => void // eslint-disable-line promise/prefer-await-to-callbacks
  ): void {
    if (!(namespace in this.sockets)) {
      throw new Error(`No socket for namespace ${namespace}`);
    }
    this.sockets[namespace].on(event, callback);
  }

  public removeCallbacks(namespace: string, event: string) {
    if (!(namespace in this.sockets)) {
      throw new Error(`No socket for namespace ${namespace}`);
    }
    this.sockets[namespace].off(event);
  }

  public onConnect(): void {
    // console.log('onConnect', namespace, event)
  }

  public onDisconnect(): void {
    // console.log('onDisconnect', namespace, event)
    this.onUnauthorisedFired = false;
  }

  public onConnectError(namespace: string, err: { description: number }): void {
    if (
      err.description === 401 &&
      !this.onUnauthorisedFired &&
      this.onUnauthorisedCB
    ) {
      this.onUnauthorisedCB();
      this.onUnauthorisedFired = true;
    }
  }

  public emit(
    namespace: string,
    message: string,
    payload: Record<string, unknown>,
    cb: (resp: Promise<unknown>) => void // eslint-disable-line promise/prefer-await-to-callbacks
  ) {
    if (!(namespace in this.sockets)) {
      throw new Error(`No socket for namespace ${namespace}`);
    }

    return new Promise((resolve) => {
      const interceptCB = function (resp: Promise<unknown>): void {
        resolve(resp);
        if (cb) {
          cb(resp); // eslint-disable-line promise/prefer-await-to-callbacks
        }
      };

      this.sockets[namespace].emit(message, payload, interceptCB);
    });
  }
}

export default new SocketIOClient();
