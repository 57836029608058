import create from 'zustand';
import { persist } from 'zustand/middleware';
import type { SortingMethod } from './models';

interface BrowserSettings {
  autoload: boolean;
  toggleAutoload: () => void;
  sortBy: SortingMethod;
  setSortBy: (s: SortingMethod) => void;
}

export const useBrowserConfig = create<BrowserSettings>(
  persist(
    (set) => ({
      autoload: false,
      toggleAutoload: () => set((state) => ({ autoload: !state.autoload })),

      sortBy: 'name',
      setSortBy: (sortBy: SortingMethod) => set({ sortBy }),
    }),
    {
      name: 'braggy:filebrowser',
      version: 1,
    }
  )
);
