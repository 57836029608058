import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';
import styles from './FileBrowser.module.css';

interface Props {
  value: string;
  onChange: (val: string) => void;
}

function FilterInput(props: Props) {
  const { value, onChange } = props;

  return (
    <div className={styles.filter}>
      <InputGroup size="sm">
        <InputGroup.Text as="label" htmlFor="filter">
          Filter
        </InputGroup.Text>
        <FormControl
          id="filter"
          className="pr-4"
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
        />
      </InputGroup>
      <Button
        className={styles.clearBtn}
        size="sm"
        variant="link"
        aria-label="Clear"
        onClick={() => onChange('')}
        disabled={value.length === 0}
      >
        <XCircle />
      </Button>
    </div>
  );
}

export default FilterInput;
