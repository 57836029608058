import {
  ColorMapSelector,
  Domain,
  DomainSlider,
  GridToggler,
  InteractionInfo,
  ScaleSelector,
  ScaleType,
  Separator,
  ToggleBtn,
  Toolbar,
} from '@h5web/lib';
import { Gem, Calendar, GraphUp } from 'react-bootstrap-icons';
import type { Histogram } from '../../app/models';
import { useImageConfig } from '../config';
import { CIRCLE_PROFILE_KEY, LINE_PROFILE_KEY, ZOOM_KEY } from '../utils';
import RingControl from './RingControl';
import { fetchMetadata } from '../../client/api';
import { useSelector } from 'react-redux';
import {
  selectImagePath,
} from '../../imageview/imageViewSlice';
import type { BraggyMetadata } from '../../app/models';

export const INTERACTIONS: InteractionInfo[] = [
  { shortcut: 'Drag', description: 'Pan' },
  { shortcut: `${LINE_PROFILE_KEY}+Drag`, description: 'Draw a profile line' },
  {
    shortcut: `${CIRCLE_PROFILE_KEY}+Drag`,
    description: 'Draw a profile circle',
  },
  { shortcut: `${ZOOM_KEY}+Drag`, description: 'Select to zoom' },
  { shortcut: 'Wheel', description: 'Zoom' },
];

interface Props {
  dataDomain: Domain;
  histogram: Histogram;
}

function gaussianValues(X: number, mean: number, sigma: number) {
  return (Math.E ** (-0.5 * ((X - mean) / sigma) ** 2));
}

function ImageToolbar(props: Props) {
  const { dataDomain, histogram } = props;

  const {
    customDomain,
    setCustomDomain,
    colorMap,
    setColorMap,
    scaleType,
    setScaleType,
    showGrid,
    toggleGrid,
    showRings,
    toggleRings,
    ringColor,
    getCssRingColor,
    setRingColor,
    invertColorMap,
    toggleColorMapInversion,
    showProfile,
    toggleProfile,
  } = useImageConfig();
  const path = useSelector(selectImagePath);

  return (
    <Toolbar interactions={INTERACTIONS}>
      <ToggleBtn
        value={false}
        icon={Gem}
        onToggle={() => {
          if (typeof path !== 'undefined') {
            fetchMetadata(path).then(response => {
              const outhdr = JSON.parse(JSON.stringify({ ...response.hdr } as Partial<BraggyMetadata>));
              const wave = parseFloat(outhdr.braggy_hdr.wavelength);
              const waveScaled = gaussianValues(wave, 1, 0.1) *  props.dataDomain[1] / 200;
              setCustomDomain([waveScaled, props.dataDomain[1]]);
            });
          };
        }}
        label="AutoContrast"
      />

      <Separator />

      <DomainSlider
        dataDomain={dataDomain}
        customDomain={customDomain}
        scaleType={scaleType}
        onCustomDomainChange={setCustomDomain}
        histogram={histogram}
      />

      <Separator />

      <RingControl
        showRings={showRings}
        toggleRings={toggleRings}
        ringColor={ringColor}
        getCssRingColor={getCssRingColor}
        setRingColor={setRingColor}
      />

      <Separator />

      <ColorMapSelector
        value={colorMap}
        onValueChange={setColorMap}
        invert={invertColorMap}
        onInversionChange={toggleColorMapInversion}
      />

      <Separator />

      <ToggleBtn
        value={showProfile}
        icon={GraphUp}
        onToggle={toggleProfile}
        label="Profile"
      />

      <Separator />

      <ToggleBtn
        value={false}
        icon={Calendar}
        onToggle={() => {
            window.open("https://vuo.elettra.trieste.it/pls/vuo/public_calendar.show_calendar?frm_facility=E&BEAMLINE=X-RAY+DIFFRACTION+2", "_child1");
          }}
        label="Calendar"
      />

      <GridToggler value={showGrid} onToggle={toggleGrid} />

      <ScaleSelector
        value={scaleType}
        onScaleChange={setScaleType}
        options={[
          ScaleType.Linear,
          ScaleType.Log,
          ScaleType.SymLog,
          ScaleType.Sqrt,
        ]}
      />
    </Toolbar>
  );
}

export default ImageToolbar;
