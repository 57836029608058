import ImageView from './ImageView';
import { imageStore } from '../client/stores';

interface Props {
  path: string | undefined;
  toolbarContainer: HTMLDivElement | undefined;
}

export default function ImageViewContainer(props: Props) {
  const { path, toolbarContainer } = props;

  if (path === undefined) {
    return <p style={{ padding: '1.5rem' }}>Please select a file.</p>;
  }

  const image = imageStore.get(path);
  return (
    <ImageView
      key={path} // avoid flash and stale domain when switching dataset
      data={image.data}
      braggyHeader={image.hdr.braggy_hdr}
      histogram={image.histogram}
      path={path}
      toolbarContainer={toolbarContainer}
    />
  );
}
