import {
  Domain,
  HeatmapVis,
  ScaleType,
  useSafeDomain,
  useVisDomain,
} from '@h5web/lib';
import ndarray from 'ndarray';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import type { Vector2 } from 'three';
import type { BraggyHeader, Histogram } from '../app/models';
import { useImageConfig } from './config';
import styles from './ImageView.module.css';
import ProfileSelectionTool from './profile/ProfileSelectionTool';
import ProfileView from './profile/ProfileView';
import ResolutionOverlay from './rings/ResolutionOverlay';
import ImageToolbar from './toolbar/ImageToolbar';
import Tooltip from './Tooltip';
import { ZOOM_KEY } from './utils';

interface Props {
  data: Float32Array;
  braggyHeader: BraggyHeader;
  histogram: Histogram;
  path: string;
  toolbarContainer: HTMLDivElement | undefined;
}

export default function ImageView(props: Props) {
  const { data, braggyHeader, histogram, path, toolbarContainer } = props;
  const pathMembers = path.split('/');
  const fileName = pathMembers[pathMembers.length - 1];

  const {
    img_height,
    img_width,
    strict_positive_min,
    positive_min,
    min,
    std,
  } = braggyHeader;

  const {
    customDomain,
    colorMap,
    invertColorMap,
    scaleType,
    showGrid,
    showRings,
    ringColor,
    getCssRingColor,
    showProfile,
    toggleProfile,
  } = useImageConfig();

  const [profileData, setProfileData] = useState<number[]>();

  const dataArray = ndarray(data, [img_height, img_width]);
  const domain = useMemo<Domain>(() => {
    if (scaleType === ScaleType.Log) {
      return [strict_positive_min, std];
    }

    if (scaleType === ScaleType.Sqrt) {
      return [positive_min, std];
    }

    return [min, std];
  }, [min, positive_min, scaleType, std, strict_positive_min]);

  const visDomain = useVisDomain(customDomain, domain);
  const [safeDomain] = useSafeDomain(visDomain, domain, scaleType);

  useEffect(() => {
    document.title = fileName;
  }, [fileName]);

  function isInsideImage(p: Vector2) {
    return p.x < img_height && p.x >= 0 && p.y < img_width && p.y >= 0;
  }

  return (
    <>
      {toolbarContainer &&
        createPortal(
          <ImageToolbar dataDomain={domain} histogram={histogram} />,
          toolbarContainer
        )}

      {showProfile && (
        <div className={styles.profileContainer}>
          <ProfileView data={profileData} />
        </div>
      )}
      <div className={styles.heatmapContainer}>
        <HeatmapVis
          dataArray={dataArray}
          domain={safeDomain}
          scaleType={scaleType}
          colorMap={colorMap}
          invertColorMap={invertColorMap}
          showGrid={showGrid}
          renderTooltip={({ xi, yi }) => (
            <Tooltip
              xIndex={xi}
              yIndex={yi}
              value={dataArray.get(yi, xi)}
              braggyHeader={braggyHeader}
            />
          )}
          flipYAxis
          // Include the HDF5 container name for HDF5 datasets
          title={
            fileName.endsWith('.dataset')
              ? `${pathMembers[pathMembers.length - 2]}::${fileName.replace(
                  /\.h5\.dataset$/u, // Remove extensions from dataset name
                  ''
                )}`
              : fileName
          }
          interactions={{ selectToZoom: { modifierKey: ZOOM_KEY } }}
        >
          {showRings && (
            <ResolutionOverlay
              ringColor={getCssRingColor(ringColor)}
              braggyHeader={braggyHeader}
            />
          )}
          <ProfileSelectionTool
            onSelection={(pts) => {
              setProfileData(
                pts
                  .filter(isInsideImage)
                  .map((p) => dataArray.get(Math.floor(p.y), Math.floor(p.x)))
              );
              if (!showProfile) {
                toggleProfile();
              }
            }}
            color={getCssRingColor(ringColor)}
          />
        </HeatmapVis>
      </div>
    </>
  );
}
