import { createFetchStore } from 'react-suspense-fetch';
// False positive: `swr/immutable` is part of `swr`
import useSWRImmutable from 'swr/immutable'; // eslint-disable-line import/no-extraneous-dependencies
import create from 'zustand';
import { fetchDirectory, fetchFile } from './api';
import type { Entry } from '../app/models';

export const imageStore = createFetchStore(fetchFile);

interface ProgressState {
  progress: number;
  resetProgress: () => void;
}

export const useProgress = create<ProgressState>((set) => ({
  progress: 0,
  resetProgress: () => set({ progress: 0 }),
}));

export function useDirectory(path: string): Entry[] {
  const { data } = useSWRImmutable<Entry[]>(path, fetchDirectory, {
    suspense: true,
  });
  return data as Entry[];
}
