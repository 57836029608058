import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import history from '../app/history';
import { logIn } from '../client/api';
import type { User } from './models';
import type { ValidationErrors } from '../app/models';
import type { Location } from 'history';

export const updateUser = createAsyncThunk<
  User,
  { username: string; password: string; from: Location | string },
  { rejectValue: ValidationErrors }
>('users/updateUser', async (params, { rejectWithValue }) => {
  try {
    const { username, password, from } = params;
    const { token, user } = await logIn(username, password);

    sessionStorage.setItem('token', token);
    history.replace(from);

    return user;
  } catch (error_) {
    const error: AxiosError<ValidationErrors> = error_; // cast the error for access
    if (!error.response) {
      throw error_;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
});

interface UsersState {
  error: string | null | undefined;
  user: User | null;
  loading: boolean;
}

const initialState: UsersState = {
  user: null,
  error: null,
  loading: false,
};

// ImmerJS used for state in createSlice so assignment can
// be done directly to state
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    removeUser: (state) => {
      state.user = null;
      state.loading = false;
      sessionStorage.removeItem('token');
      history.replace('/login');
    },
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { removeUser } = usersSlice.actions;
export default usersSlice.reducer;
