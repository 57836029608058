import FileBrowser from '../filebrowser/FileBrowser';
import { Panel } from './models';

interface Props {
  activePanel: Panel | undefined;
}

function MenuSidePanel(props: Props) {
  const { activePanel } = props;

  if (activePanel === Panel.Files) {
    return <FileBrowser />;
  }

  return null;
}

export default MenuSidePanel;
