import type { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  children: ReactNode;
  path: string;
}

export function PrivateRoute(props: Props) {
  const { path, children } = props;

  return (
    <Route
      path={path}
      render={({ location }) => {
        return sessionStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
