import { Suspense, useState } from 'react';
import { GripVertical } from 'react-bootstrap-icons';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { imageStore } from '../client/stores';
import ImageLoader from '../imageview/ImageLoader';
import ImageViewContainer from '../imageview/ImageViewContainer';
import { selectImagePath } from '../imageview/imageViewSlice';
import Menu from '../menu/Menu';
import MenuSidePanel from '../menu/MenuSidePanel';
import { Panel } from '../menu/models';
import styles from './MainPage.module.css';
import ErrorFallback from './ErrorFallback';

function MainPage() {
  const path = useSelector(selectImagePath);

  const [toolbarElem, setToolbarElem] = useState<HTMLDivElement>();
  const [activePanel, setActivePanel] = useState<Panel | undefined>(
    Panel.Files
  );

  function togglePanel(panel: Panel) {
    setActivePanel(activePanel !== panel ? panel : undefined);
  }

  if (!('ResizeObserver' in window)) {
    throw new Error(
      "Your browser's version is not supported. Please use a more recent browser version."
    );
  }

  return (
    <div className={styles.root}>
      <Menu activePanel={activePanel} onTogglePanel={togglePanel} />

      <ReflexContainer className={styles.container} orientation="vertical">
        <ReflexElement
          className={styles.panel}
          aria-label={activePanel}
          style={{ display: activePanel ? undefined : 'none' }}
          flex={20}
          minSize={200}
        >
          <MenuSidePanel activePanel={activePanel} />
        </ReflexElement>

        <ReflexSplitter
          className={styles.handle}
          style={{ display: activePanel ? undefined : 'none' }}
        >
          <GripVertical preserveAspectRatio="xMidYMid slice" />
        </ReflexSplitter>

        <ReflexElement className={styles.content} flex={80} minSize={500}>
          <div
            ref={(elem) => setToolbarElem(elem ?? undefined)}
            className={styles.toolbar}
          />

          <ErrorBoundary
            FallbackComponent={(fallbackProps) => (
              <ErrorFallback path={path} {...fallbackProps} />
            )}
            resetKeys={[path]}
            onError={path ? () => imageStore.evict(path) : undefined}
          >
            <Suspense fallback={<ImageLoader />}>
              <ImageViewContainer path={path} toolbarContainer={toolbarElem} />
            </Suspense>
          </ErrorBoundary>
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}

export default MainPage;
