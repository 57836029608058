import { Folder, Image, FileEarmarkText } from 'react-bootstrap-icons';

function EntryIcon({ type }: { type: string }) {
  switch (type) {
    case 'dir':
      return <Folder />;
    case 'image':
      return <Image />;
    case 'text':
      return <FileEarmarkText />;
    default:
      return <Image />;
  }
}

export default EntryIcon;
