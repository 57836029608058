import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reduxThunk from 'redux-thunk';

import usersReducer from '../login/loginSlice';
import fileBrowserReducer from '../filebrowser/fileBrowserSlice';
import imageViewReducer from '../imageview/imageViewSlice';

export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;

export function createStore() {
  return configureStore({
    reducer: {
      users: usersReducer,
      filebrowser: fileBrowserReducer,
      imageview: imageViewReducer,
    },
    middleware: [
      reduxThunk,
      ...(process.env.REACT_APP_REDUX_LOGGER_ENABLED === 'true'
        ? [logger]
        : []),
    ],
  });
}
