import { Overlay, useCameraState } from '@h5web/lib';
import { useThree } from '@react-three/fiber';
import type { SVGProps } from 'react';
import { Vector2, Vector3 } from 'three';

interface Props extends SVGProps<SVGCircleElement> {
  startPoint: Vector2;
  endPoint: Vector2;
}

function SelectionCircle(props: Props) {
  const { startPoint, endPoint, stroke = 'black', ...restSvgProps } = props;

  const { width, height } = useThree((state) => state.size);

  const htmlSelection = useCameraState(
    (camera, context) => {
      const { dataToWorld, cameraToHtml } = context;

      function dataToHtml(dataPt: Vector2) {
        const worldPt = dataToWorld(dataPt);
        const cameraPt = new Vector3(worldPt.x, worldPt.y, 0).project(camera);
        return cameraToHtml(cameraPt);
      }

      return {
        startPoint: dataToHtml(startPoint),
        endPoint: dataToHtml(endPoint),
      };
    },
    [startPoint, endPoint]
  );

  const { x: x1, y: y1 } = htmlSelection.startPoint;

  const r = htmlSelection.endPoint.distanceTo(htmlSelection.startPoint);

  return (
    <Overlay>
      <svg width={width} height={height}>
        <circle
          cx={x1}
          cy={y1}
          r={r}
          stroke={stroke}
          fillOpacity={0}
          {...restSvgProps}
        />
      </svg>
    </Overlay>
  );
}

export default SelectionCircle;
