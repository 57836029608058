import type { FileCreationEvent } from '../client/models';

export function isFileCreationEvent(val: unknown): val is FileCreationEvent {
  if (typeof val !== 'object') {
    return false;
  }
  return val !== null && 'dir_path' in val && 'file' in val;
}

export function assertIsFileCreationEvent(
  val: unknown
): asserts val is FileCreationEvent {
  if (!isFileCreationEvent(val)) {
    throw new TypeError('Expected file creation event');
  }
}
