import { SelectionLine } from '@h5web/lib';
import type { PersistedSelection } from './models';
import SelectionCircle from './SelectionCircle';

interface Props extends PersistedSelection {
  color: string;
}

function PersistedSelectionShape(props: Props) {
  const { selection, color, shape } = props;
  const { startPoint, endPoint } = selection;

  const SelectionShape = shape === 'line' ? SelectionLine : SelectionCircle;

  return (
    <SelectionShape
      stroke={color}
      startPoint={startPoint}
      endPoint={endPoint}
    />
  );
}

export default PersistedSelectionShape;
