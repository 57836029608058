import axios from 'axios';
import qs from 'qs';
import history from '../app/history';
import type {
  BraggyHeader,
  BraggyMetadata,
  BraggyFile,
  HistMetadata,
  Entry,
} from '../app/models';
import type { UserData } from '../login/models';
import type { DirectoryResponse, UserResponse } from './models';
import { useProgress } from './stores';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_DAIQUIRI_URL || ''}/api`,
  paramsSerializer: (ps: string) => {
    return qs.stringify(ps, { arrayFormat: 'repeat' });
  },
});

// Add `Authorization` header to every request if user is logged in
client.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  return token
    ? {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
      }
    : config;
});

// If user is not authorized, clear token and redirect to login page
// eslint-disable-next-line promise/prefer-await-to-callbacks
client.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    sessionStorage.removeItem('token');
    history.replace('/login');
  }

  return Promise.reject(error);
});

export async function fetchDirectory(path: string): Promise<Entry[]> {
  const params = { path };

  const { data } = await client.get<DirectoryResponse>(
    '/filebrowser/directory',
    { params }
  );

  return data.rows;
}

export async function fetchMetadata(path: string): Promise<BraggyMetadata> {
  const params = { path };

  const [
    { data: metadataResponse },
  ] = await Promise.all([
    client.get<BraggyMetadata>('/filebrowser/loadfile', { params }),
  ]);
  return {
    ...metadataResponse,
  };
}

export async function fetchContent(path: string): Promise<ArrayBuffer> {
  const params = { path };

  const [
    { data: contentResponse },
  ] = await Promise.all([
    client.get<ArrayBuffer>('/filebrowser/file', {
      params,
      responseType: 'arraybuffer',
      },
    ),
  ]);
  return new Float32Array(contentResponse);
}

export async function fetchFile(path: string): Promise<BraggyFile> {
  const params = { path };

  const [
    { data: metadataResponse },
    { data: contentResponse },
    { data: histResponse },
    { data: histMetaResponse },
  ] = await Promise.all([
    client.get<BraggyMetadata>('/filebrowser/loadfile', { params }),
    client.get<ArrayBuffer>('/filebrowser/file', {
      params,
      responseType: 'arraybuffer',
      onDownloadProgress: (evt: ProgressEvent) => {
        useProgress.setState({ progress: (evt.loaded / evt.total) * 100 });
      },
    }),
    client.get<ArrayBuffer>('/filebrowser/hist', {
      params,
      responseType: 'arraybuffer',
    }),
    client.get<HistMetadata>('/filebrowser/loadhist', { params }),
  ]);

  return {
    ...metadataResponse,
    data: new Float32Array(contentResponse),
    histogram: {
      values: [...new Float32Array(histResponse)],
      ...histMetaResponse,
    },
  };
}

export async function logIn(
  username: string,
  password: string
): Promise<UserData> {
  const { data: userResponse } = await client.post<UserResponse>(
    '/authenticator/login',
    {
      client: 'web',
      username,
      password,
    }
  );

  const { data, token, ...user } = userResponse;
  return { token, user: { username, ...user } };
}

export async function fetchRootPath(): Promise<string> {
  const params = { path: '.' };

  const { data } = await client.get<DirectoryResponse>(
    '/filebrowser/directory',
    { params }
  );
  const { abs_path } = data;

  return abs_path;
}
