import { Download, Power, Files, Window, Eyeglasses, Reply, ArrowClockwise, Megaphone } from 'react-bootstrap-icons';
import { Panel } from './models';
import styles from './Menu.module.css';
import { removeUser } from '../login/loginSlice';
import { fetchMetadata, fetchContent } from '../client/api';
import history from '../app/history';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSWRConfig } from 'swr';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectImagePath,
} from '../imageview/imageViewSlice';
import {
  selectDirectoryPath,
  selectRootPath,
  setDirectoryPath,
  getRootPath,
} from '../filebrowser/fileBrowserSlice';
import { setImagePath } from '../imageview/imageViewSlice';
import logoE from './logoE.png';
import logoI from './logoI.png';
import type { BraggyMetadata } from '../app/models';

interface Props {
  activePanel: Panel | undefined;
  onTogglePanel: (panel: Panel) => void;
}

export default function Menu(props: Props) {
  const { activePanel, onTogglePanel } = props;
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const rootPath = useSelector(selectRootPath);
  const currentPath = useSelector(selectDirectoryPath);
  const path = useSelector(selectImagePath);

  function pickEntry(path: string, type: string) {
    if (type === 'dir') {
      dispatch(setDirectoryPath(path));
    } else {
      dispatch(setImagePath(path));
    }
  }

  return (
    <div className={styles.menu} style={{ textAlign: 'center' }} >
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="elettra-tooltip">Quick Links</Tooltip>}
      >
        <Button
          variant="link"
          aria-label="Elettra"
          onClick={() => {
            window.open("https://www.elettra.eu/elettra-beamlines/xrd2.html", "_child1");
          }}
        >
                <img
                  className={styles.logo}
                  style={{ maxWidth: '100%' }}
                  src={ logoE }
                  width="200"
                  alt="E"
                />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="elettra-tooltip">SynchWeb link</Tooltip>}
      >
        <Button
          variant="link"
          aria-label="ISPyB"
          onClick={() => {
            if (typeof path === 'undefined') {
              window.open("https://synchweb.elettra.eu/cal/", "_child2");
            } else {
              const filename = path.split('/');
              var proposal = '';
              if (currentPath.split('/')[0].includes('online4')) {
                proposal = currentPath.split('/')[1];
              } else {
                proposal = currentPath.split('/')[0]
              }
              window.open('https://synchweb.elettra.eu/dc/visit/mx' + proposal + '/s/' + filename[filename.length - 1].replace(new RegExp('.....\.cbf$'), ''), "_child2");
            }
          }}
        >
                <img
                  className={styles.logo}
                  style={{ maxWidth: '65%', margin: 'auto' }}
                  src={ logoI }
                  width="100"
                  alt="I"
                />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="refresh-tooltip">Refresh List</Tooltip>}
      >
        <Button
          variant="link"
          onClick={() => {
            mutate(currentPath);
            pickEntry(currentPath, 'dir');
          }}
        >
          <ArrowClockwise />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="header-tooltip">Go to Root Folder</Tooltip>}
      >
        <Button
          variant="link"
          aria-label="Root"
          onClick={() => {
              if (currentPath.split('/')[0].includes('online4')) {
                pickEntry(currentPath.split('/')[0] + '/' + currentPath.split('/')[1], 'dir');
              } else {
                pickEntry(currentPath.split('/')[0], 'dir');
              }
          }}
        >
          <Reply />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="refresh-tooltip">Clone Window</Tooltip>}
      >
        <Button
          variant="link"
          onClick={() => {
            if (typeof path !== 'undefined') {
              window.open(window.location.origin + "?file=" + path, "_child3");
            } else {
              window.open(window.location.origin + "?file=" + currentPath, "_child3");
            }
          }}
        >
          <Window />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="save-tooltip">Save Frame</Tooltip>}
      >
        <Button
          variant="link"
          onClick={() => {
            if (typeof path !== 'undefined') {
              fetchMetadata(path).then(response => {
                var filename = path.split('/');
                const imgWidth = response.hdr.braggy_hdr["img_width"];
                const imgHeight = response.hdr.braggy_hdr["img_height"];
                fetchContent(path).then(response => {
                  var FileSaver = require('file-saver');
                  FileSaver.saveAs(new Blob([new Int32Array(response)]), filename[filename.length - 1] +
                     "-W" + imgWidth +
                     "xH" + imgHeight +
                     "x4B.raw"
                  );
                })
              });
            }
          }}
        >
          <Download />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="header-tooltip">Show Header</Tooltip>}
      >
        <Button
          variant="link"
          aria-label="Show Header"
          onClick={() => {
            if (typeof path !== 'undefined') {
              fetchMetadata(path).then(response => {
                var outhdr = JSON.parse(JSON.stringify({ ...response.hdr } as Partial<BraggyMetadata>))["parsed_ext_hdr"];
                delete outhdr["Trim_file"];
                delete outhdr["Excluded_pixels"];
                delete outhdr["Flat_field"];
                outhdr["img_width"] = response.hdr.braggy_hdr["img_width"]
                outhdr["img_height"] = response.hdr.braggy_hdr["img_height"]
                alert(JSON.stringify(outhdr, null, 2));
              });
            };
          }}
        >
          <Eyeglasses />
        </Button>
      </OverlayTrigger>
    <div className="d-grid gap-3">
      <div style={{ marginTop: '1em' }}></div>
    </div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="logout-tooltip">Log out</Tooltip>}
      >
        <Button
          variant="link"
          aria-label="Log out"
          onClick={() => {
            dispatch(removeUser());
            history.push('/');
          }}
        >
          <Power />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="filetoggle-tooltip">Toggle file panel</Tooltip>}
      >
      <Button
        className={styles.logoutBtn}
        variant="link"
        aria-label="Toggle file panel"
        data-active={activePanel === Panel.Files || undefined}
        onClick={() => onTogglePanel(Panel.Files)}
      >
        <Files />
      </Button>
      </OverlayTrigger>
{/*
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="feedback-tooltip">Leave feedback</Tooltip>}
      >
        <Button
          variant="link"
          href="mailto:noreply@elettra.eu?subject=Feedback"
          target="_blank"
          aria-label="Feedback"
        >
          <Megaphone />
        </Button>
      </OverlayTrigger>
*/}
    </div>
  );
}
