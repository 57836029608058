import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SocketIOClient from '../client/SocketIOClient';
import { setImagePath } from '../imageview/imageViewSlice';
import { setDirectoryPath } from './fileBrowserSlice';
import { getParentDirectory } from './utils';

// Socket namespace
const NAMESPACE = 'filebrowser';

export function useSocketCallback(
  callbackName: string,
  callback: (payload: unknown) => void,
  deps: React.DependencyList
) {
  useEffect(() => {
    SocketIOClient.addNamespace(NAMESPACE);
    SocketIOClient.addCallback(NAMESPACE, callbackName, callback);
    SocketIOClient.openSockets();

    return () => {
      SocketIOClient.removeCallbacks(NAMESPACE, callbackName);
      SocketIOClient.closeSockets();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackName, ...deps]);
}

export function useLoadPathFromUrl() {
  const query = new URLSearchParams(useLocation().search);
  const initialPath = query.get('file');
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialPath) {
      dispatch(setImagePath(initialPath));
      dispatch(setDirectoryPath(getParentDirectory(initialPath)));
    }
  }, [dispatch, initialPath]);
}
